import React from 'react';

import Layout from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../components/Seo';
import { graphql } from 'gatsby';
import { Gallery } from '../components/Gallery';

const IndexPage = ({data}) => {
  return (<Layout fullMenu subPage>
      <Seo
        description=""
        keywords=""
        title="Focení zvířat"
      />
      <article id="main">
        <header id='family-photo' className='sub-page'>
          <StaticImage
            src='../assets/images/pic04.jpg' alt='Focení zvířat'
            placeholder='blurred'
            objectFit='cover'
            className='static-image'
            objectPosition='0% 0%'
          />
          <div className='header-text'>
            <h1>Focení zvířat</h1>
            <p>Pozor vyletí ptáček :)</p>
          </div>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            <h3>I kočky by rády selfie.</h3>
            <p>
              Pro většinu z nás je domácí mazlíček plnohodnotný člen rodiny. Pokud si přejete fotky vašeho pejska nebo třeba slona, nic není problém
            </p>

            <hr />

            <div>
              <Gallery data={data} />
            </div>

            <hr />
            <h4>Pamlsky pro chlupáče s sebou</h4>
            <p>
              .
            </p>

          </div>
        </section>
      </article>
    </Layout>
  );
}

export const pageQuery = graphql`
  query AnimalsImages {
    allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "gallery/animals"}}
        sort: {order: DESC, fields: name}
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 276
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
          name
        }
      }
    }
  }
`

export default IndexPage;
